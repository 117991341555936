import { VCombobox } from 'vuetify/lib/components/VCombobox';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(VCombobox, {
    staticClass: "border-label-input",
    staticStyle: { cursor: "text" },
    attrs: {
      label: _vm.label,
      outline: "",
      "append-icon": "mdi-clock-outline",
      items: _vm.times,
      rules: _vm.rules,
      autocomplete: _vm.autocomplete,
      "auto-select-first": "",
      type: _vm.type,
      disabled: _vm.disabled,
      "item-text": "timeLabel",
      "item-value": "timeValue",
      "search-input": _vm.search,
      "hide-selected": ""
    },
    on: {
      "update:searchInput": function($event) {
        _vm.search = $event
      },
      "update:search-input": function($event) {
        _vm.search = $event
      },
      keyup: _vm.setTime
    },
    model: {
      value: _vm.timeInput,
      callback: function($$v) {
        _vm.timeInput = $$v
      },
      expression: "timeInput"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }