<template>
  <v-combobox
    class="border-label-input"
    v-model="timeInput"
    :label="label"
    outline
    append-icon="mdi-clock-outline"
    :items="times"
    :rules="rules"
    :autocomplete="autocomplete"
    auto-select-first
    :type="type"
    :disabled="disabled"
    item-text="timeLabel"
    item-value="timeValue"
    :search-input.sync="search"
    style="cursor: text;"
    @keyup="setTime"
    hide-selected
  ></v-combobox>
</template>

<script>
export default {
  props: {
    value: String,
    label: String,
    rules: Array,
    autocomplete: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'time',
    },
  },
  data() {
    return {
      times: [],
      search: '',
    };
  },
  mounted() {
    this.populateTimes();
  },
  methods: {
    populateTimes() {
      let hours;
      let minutes;
      let ampm;
      let hours24;

      for (let i = 0; i <= 1440; i += 15) {
        hours = Math.floor(i / 60);
        minutes = i % 60;
        if (minutes < 10) {
          minutes = `0${minutes}`; // adding leading zero
        }
        hours24 = hours;
        if (hours24 < 10) {
          hours24 = `0${hours24}`; // adding leading zero
        } else if (hours24 === 24) {
          hours24 = '00'; // adding leading zero
        }
        ampm = hours % 24 < 12 ? 'AM' : 'PM';
        hours %= 12;
        if (hours === 0) {
          hours = 12;
        }
        this.times.push({
          timeLabel: `${hours}:${minutes} ${ampm}`,
          timeValue: `${hours24}:${minutes}`,
        });
      }
    },
    setTime() {
      this.timeInput = this.search;
    },
    closeMenu() {
      this.$refs.combobox.isMenuActive = false;
      this.$refs.combobox.blur();
    },
  },
  computed: {
    timeInput: {
      get() {
        return this.value;
      },
      set(newValue) {
        if (newValue && newValue.timeValue) {
          this.$emit('update:value', newValue.timeValue);
        } else {
          this.$emit('update:value', newValue);
        }
      },
    },
  },
};
</script>

<style scoped>
.disabled {
  background-color: #ffffff;
}
</style>

<style>
input[type='time'] {
  cursor: text;
}
input[type='time']::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
</style>
